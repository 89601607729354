import React, { FC, useState } from 'react';
import { ChatInputProps } from './interfaces';
import { TextField, IconButton, IconButtonThemes } from 'wix-ui-tpa/cssVars';
import { useTranslation } from '@wix/yoshi-flow-editor';
import SendIcon from '../../../../../../assets/images/send-icon.svg';

import { classes } from './ChatInput.st.css';

export const CHAT_INPUT_HOOKS = {
  userInput: 'assistant-chat-user-input',
  sendButton: 'assistant-chat-send-icon',
};

export const ChatInput: FC<ChatInputProps> = ({ onSubmit, disabled }) => {
  const [value, setValue] = useState<string>('');
  const { t } = useTranslation();

  const submitValue = () => {
    if (value) {
      onSubmit(value);
      setValue('');
    }
  };

  const submitValueByEnter: React.KeyboardEventHandler<HTMLInputElement> = (
    event,
  ) => {
    if (event.key === 'Enter') {
      submitValue();
    }
  };

  const changeValue: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const inputValue = (event.target as HTMLInputElement).value;
    setValue(inputValue);
  };

  return (
    <div className={classes.input}>
      <TextField
        className={classes.textField}
        data-hook={CHAT_INPUT_HOOKS.userInput}
        suffix={
          <IconButton
            disabled={!value}
            data-hook={CHAT_INPUT_HOOKS.sendButton}
            className={classes.button}
            upgrade
            icon={<SendIcon />}
            onClick={submitValue}
            theme={IconButtonThemes.Box}
          />
        }
        placeholder={t('ai_assistant_input_placeholder')}
        onKeyDown={submitValueByEnter}
        value={value}
        onChange={changeValue}
      />
    </div>
  );
};
