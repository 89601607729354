import React, { FC } from 'react';
import { FormAssistantChatLayoutProps } from './interfaces';
import { createPortal } from 'react-dom';
import Draggable from 'react-draggable';
import { DRAG_HANDLE_ID } from './ChatHeader/ChatHeader';
import { CustomDialog } from 'wix-ui-tpa';

import styles from './FormAssistantChatLayout.scss';

const CHAT_WIDTH = 500;
const CHAT_SIDE_MARGIN = 50;

export const FormAssistantChatLayout: FC<FormAssistantChatLayoutProps> = ({
  children,
  open,
  isMobile,
}) => {
  if (isMobile) {
    return (
      <CustomDialog isOpen={open} appendToBody>
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
          }}
        >
          {children}
        </div>
      </CustomDialog>
    );
  }

  if (open) {
    return createPortal(
      <Draggable handle={`#${DRAG_HANDLE_ID}`}>
        <div
          className={styles.container}
          style={{ left: window.innerWidth - CHAT_WIDTH - CHAT_SIDE_MARGIN }}
        >
          {children}
        </div>
      </Draggable>,
      document.getElementById('SITE_CONTAINER') || document.body,
    );
  }

  return null;
};
